import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CompanySelect } from "./CompanySelect";
import { SelectedCompanies } from "./SelectedCompanies";
import { useRecoilState } from "recoil";
import { portfolioScannerAtom } from "../../../atoms/portfolioScannerAtom";
import { portfolioScannerConfigAtom } from "../../../atoms/portfolioScannerConfigAtom";
import { useEffect, useState } from "react";
import { api } from "../../../api";
import { WideLoader } from "../../../components/WideLoader";
import { useDebounce } from "use-debounce";

export const ConfigPanel = () => {
  const [scannerState, setScannerState] = useRecoilState(portfolioScannerAtom);
  const [displayHiddenPosts, setDisplayHiddenPosts] = useState(
    scannerState.displayHiddenPosts,
  );
  const [configState, setConfigState] = useRecoilState(
    portfolioScannerConfigAtom,
  );
  const [loading, setLoading] = useDebounce(true, 100);

  useEffect(() => {
    if (!scannerState.isConfigOpened) return;

    if (scannerState.companyIds.length === 0) {
      setConfigState({ ...configState });
      setLoading(false);
      return;
    }

    setConfigState({ ...configState });
    setLoading(true);

    (async () => {
      try {
        const companies = await api.getCompanies(scannerState.companyIds);
        setConfigState({
          ...configState,
          selectedCompanies: companies,
        });
        setLoading(false);
      } catch {
        setConfigState({
          ...configState,
          selectedCompanies: [],
        });
        setLoading(false);
      }
    })();
  }, [scannerState.companyIds.join(","), scannerState.isConfigOpened]);

  const close = () => {
    setScannerState({ ...scannerState, isConfigOpened: false });

    setTimeout(() => {
      setConfigState({
        ...configState,
        selectedCompanies: [],
        availableCompanies: [],
      });
      setLoading(false);
    }, 500);
  };

  const save = () => {
    setScannerState({
      ...scannerState,
      companyIds: configState.selectedCompanies.map((x) => x.id),
      isConfigOpened: false,
      displayHiddenPosts: displayHiddenPosts,
    });

    setTimeout(() => {
      setConfigState({
        ...configState,
        selectedCompanies: [],
        availableCompanies: [],
      });
    }, 500);
  };

  return (
    <Dialog
      open={scannerState.isConfigOpened}
      onClose={close}
      className="relative z-10"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                        Dodaj lub usuń spółkę
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={close}
                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Zamknij</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-2 flex-1 px-4 sm:px-6">
                    <CompanySelect />
                    <div className="mt-2">
                      {loading ? <WideLoader /> : <SelectedCompanies />}
                    </div>
                  </div>
                </div>
                {/* <div>
                  <ShowHiddenPostsSwitch enabled={displayHiddenPosts} onChange={setDisplayHiddenPosts} />
                </div> */}
                <div className="flex flex-shrink-0 justify-end px-4 py-4">
                  <button
                    type="button"
                    onClick={close}
                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                  >
                    Anuluj
                  </button>
                  <button
                    onClick={save}
                    type="submit"
                    className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    Zapisz zmiany
                  </button>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
