import { Post } from "../entities/post";

const dni = [
  "Niedziela",
  "Poniedziałek",
  "Wtorek",
  "Środa",
  "Czwartek",
  "Piątek",
  "Sobota",
];
const miesiace = [
  "stycznia",
  "lutego",
  "marca",
  "kwietnia",
  "maja",
  "czerwca",
  "lipca",
  "sierpnia",
  "września",
  "października",
  "listopada",
  "grudnia",
];

const groupByToMap = <T, Q>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => Q,
) =>
  array.reduce((map, value, index, array) => {
    const key = predicate(value, index, array);
    map.get(key)?.push(value) ?? map.set(key, [value]);
    return map;
  }, new Map<Q, T[]>());

const formatDate = (published: string) => {
  const date = new Date(published);
  const nrd = date.getDay();
  const nrm = date.getMonth();
  const dzien = date.getDate();
  return `${dni[nrd]}, ${dzien} ${miesiace[nrm]}`;
};

const groupPostsByDate = (
  posts: Post[],
): Array<{ isHidden: boolean; date: string; posts: Post[] }> => {
  const map = groupByToMap(posts, (x: Post) => formatDate(x.published));
  return Array.from(map.entries()).map((x) => {
    return {
      isHidden: localStorage.getItem(x[0]) === "1",
      date: x[0],
      posts: x[1],
    };
  });
};

const groupPostsByGroup = (
  posts: Post[],
): Array<{
  group: string;
  lead: Post;
  topPosts: Post[];
  morePosts: Post[];
}> => {
  const map = groupByToMap(posts, (x: Post) => x.group);
  const groups = Array.from(map.entries()).map((x) => {
    const sortedPosts = x[1].sort((x, y) =>
      x.published >= y.published ? 1 : -1,
    );
    return {
      group: x[0],
      lead: sortedPosts[0],
      topPosts: sortedPosts.slice(1, 3),
      morePosts: sortedPosts.slice(3),
    };
  });

  return groups.sort((x, y) => (x.lead.published >= y.lead.published ? -1 : 1));
};

export { groupPostsByDate, groupPostsByGroup };
