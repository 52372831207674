import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import { AutoRefreshIcon } from "./AutoRefreshIcon";
import { useRecoilState } from "recoil";
import { portfolioScannerAtom } from "../../../atoms/portfolioScannerAtom";
import ReactGA from "react-ga4";

export const ActionPanel = () => {
  const [state, setState] = useRecoilState(portfolioScannerAtom);
  return (
    <>
      <AutoRefreshIcon />
      <button
        type="button"
        onClick={() => {
          setState({ ...state, isConfigOpened: true });
          ReactGA.event({
            category: "portfolio_scanner",
            action: "open_config_panel",
            nonInteraction: false,
          });
        }}
        className="ml-2 rounded-md bg-zinc-600 px-3 py-2 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <AdjustmentsHorizontalIcon aria-hidden="true" className="h-5 w-5" />
      </button>
    </>
  );
};
