import { Post } from "../entities/post";
import { api } from "../api";
import { ReactElement, useEffect, useState } from "react";
import { WideLoader } from "./WideLoader";
import { usePostsNotifier } from "../hooks/usePostsNotifier";
import { InView } from "react-intersection-observer";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { HiddenPostType } from "../enums/hidden-post-type";
import {
  groupPostsByDate,
  groupPostsByGroup,
} from "../services/postsGroupingService";
import { CompanyPost } from "./CompanyPost";
import { CompactCompanyPost } from "./CompactCompanyPost";

interface CompanyPostsProps {
  companyIds: number[];
  sourceIds: number[];
  postIds: number[];
  showCompanyNames: boolean;
  disableHidingPosts?: boolean;
  displayHiddenPosts?: boolean;
  children?: ReactElement;
}

class CompanyPostsState {
  isLoading = true;
  isNextPageLoading = false;
  hasNextPage = false;
  page = 1;
  posts = [] as Post[];
  connected = false;
  bookmarkedPostIds = [] as number[];
  hiddenPostIds = [] as number[];
  initialized = false;
}

export const CompanyPosts = (props: CompanyPostsProps) => {
  const [state, setState] = useState(new CompanyPostsState());
  const { onNewPost, stopWaitingForPosts } = usePostsNotifier();
  const [expandedGroups, setExpandedGroups] = useState(new Set<string>());

  const [bookmarkedStorage, setBookmarkedStorage] = useLocalStorage<number[]>(
    "bookmarkedPosts",
    state.bookmarkedPostIds,
  );

  const [hiddenStorage, setHiddenStorage] = useLocalStorage<number[]>(
    "hiddenPosts",
    state.hiddenPostIds,
  );

  const takeLast = (array: number[], n: number) => {
    return array.slice(Math.max(array.length - n, 0));
  };

  useEffect(() => {
    setBookmarkedStorage(takeLast(state.bookmarkedPostIds, 100000));
  }, [state.bookmarkedPostIds.join(",")]);

  useEffect(() => {
    setHiddenStorage(takeLast(state.hiddenPostIds, 100000));
  }, [state.hiddenPostIds.join(",")]);

  if (!state.initialized) {
    setState({
      ...state,
      hiddenPostIds: hiddenStorage,
      bookmarkedPostIds: bookmarkedStorage,
      initialized: true,
    });
  }

  const loadNextPage = () => {
    setState({ ...state, isNextPageLoading: true });

    (async () => {
      try {
        const posts = await api.getPosts(
          props.companyIds,
          props.postIds,
          props.sourceIds,
          state.page + 1,
        );
        updateComputedFields(posts.items);
        setState({
          ...state,
          isNextPageLoading: false,
          posts: [...state.posts, ...posts.items],
          hasNextPage: posts.hasNextPage,
          page: posts.pageNumber,
        });
      } catch {
        setState({ ...state, isNextPageLoading: false });
      }
    })();
  };

  const hidePost = (post: Post) => {
    state.hiddenPostIds.push(post.id);
    post.hidden = HiddenPostType.TemporarilyHidden;

    unbookmarkPost(post);
    setState({ ...state });
  };

  const unhidePost = (post: Post) => {
    state.hiddenPostIds = state.hiddenPostIds.filter((p) => p !== post.id);
    post.hidden = HiddenPostType.Visible;
    setState({ ...state });
  };

  const bookmarkPost = (post: Post) => {
    state.bookmarkedPostIds.push(post.id);
    post.bookmarked = true;
    setState({ ...state });
  };

  const unbookmarkPost = (post: Post) => {
    state.bookmarkedPostIds = state.bookmarkedPostIds.filter(
      (p) => p !== post.id,
    );
    post.bookmarked = false;
    setState({ ...state });
  };

  const updateComputedFields = (posts: Post[]) => {
    posts.forEach((post) => {
      const bookmarksSet = new Set(state.bookmarkedPostIds);
      if (bookmarksSet.has(post.id)) {
        post.bookmarked = true;
      }

      const hiddenSet = new Set(state.hiddenPostIds);
      if (hiddenSet.has(post.id)) {
        post.hidden = HiddenPostType.Hidden;
      } else {
        post.hidden = HiddenPostType.Visible;
      }
    });
  };

  const filterHiddenPosts = (posts: Post[]) => {
    return posts.filter(
      (x) => props.displayHiddenPosts || x.hidden !== HiddenPostType.Hidden,
    );
  };

  useEffect(() => {
    setState({ ...state, isLoading: true });

    (async () => {
      try {
        const posts = await api.getPosts(
          props.companyIds,
          props.postIds,
          props.sourceIds,
          1,
        );
        updateComputedFields(posts.items);
        setState({
          ...state,
          posts: posts.items,
          isLoading: false,
          hasNextPage: posts.hasNextPage,
          page: posts.pageNumber,
        });
      } catch {
        setState({ ...state, isLoading: false });
      }
    })();
  }, [props.companyIds.join(",")]);

  useEffect(() => {
    onNewPost(async (companyId: string) => {
      const id = parseInt(companyId);

      if (!props.companyIds.includes(id)) return;

      const posts = await api.getPosts(
        props.companyIds,
        props.postIds,
        props.sourceIds,
        1,
      );
      updateComputedFields(posts.items);
      setState({
        ...state,
        isLoading: false,
        hasNextPage: posts.hasNextPage,
        posts: posts.items,
        page: posts.pageNumber,
      });
    });

    return () => {
      stopWaitingForPosts();
    };
  }, []);

  if (state.isLoading) return <WideLoader />;
  if (!state.isLoading && filterHiddenPosts(state.posts).length === 0)
    return props.children || <></>;

  return (
    <>
      {groupPostsByDate(filterHiddenPosts(state.posts)).map((group) => (
        <ul key={group.date}>
          <li className="flex flex-col justify-between gap-x-6 py-5">
            <div>
              <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                {group.date}
              </div>
              <div className="mx-auto mt-5 block h-[1px] max-w-6xl bg-gray-100"></div>
            </div>
            <div className="mx-auto w-full max-w-5xl px-4 sm:px-6 lg:px-8">
              <ul className="max-w-5xl divide-y divide-gray-100 px-0 sm:px-6 lg:px-8">
                {groupPostsByGroup(group.posts).map((grouping) => (
                  <div key={grouping.group}>
                    <CompanyPost
                      key={grouping.lead.id}
                      post={grouping.lead}
                      showCompanyNames={props.showCompanyNames}
                      bookmark={(set: boolean) =>
                        set
                          ? bookmarkPost(grouping.lead)
                          : unbookmarkPost(grouping.lead)
                      }
                      hide={(set: boolean) =>
                        set
                          ? hidePost(grouping.lead)
                          : unhidePost(grouping.lead)
                      }
                    />
                    {grouping.topPosts.length > 0 && (
                      <div className="pb-5">
                        {grouping.topPosts.map((post) => (
                          <CompactCompanyPost
                            key={post.id}
                            post={post}
                            showCompanyNames={props.showCompanyNames}
                          />
                        ))}
                        {grouping.morePosts.length > 0 &&
                          (expandedGroups.has(grouping.group) ? (
                            grouping.morePosts.map((post) => (
                              <CompactCompanyPost
                                key={post.id}
                                post={post}
                                showCompanyNames={props.showCompanyNames}
                              />
                            ))
                          ) : (
                            <div className="flex gap-x-6 py-0.5">
                              <div className="min-w-12 text-right"></div>
                              <div className="flex-grow">
                                <a
                                  className="cursor-pointer text-xs"
                                  onClick={() => {
                                    expandedGroups.add(grouping.group);
                                    setExpandedGroups(new Set(expandedGroups));
                                  }}
                                >
                                  Pokaż więcej ({grouping.morePosts.length})
                                </a>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                ))}
              </ul>
            </div>
          </li>
        </ul>
      ))}

      {state.isNextPageLoading && <WideLoader />}
      {state.hasNextPage && (
        <InView
          as="div"
          onChange={(inView) => {
            inView && loadNextPage();
          }}
        ></InView>
      )}
    </>
  );
};
